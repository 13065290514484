<template>
  <div class="app-customizer">
    <v-btn
      icon
      class="app-customizer-toggler rounded-0"
      :class="$vuetify.rtl ? 'rounded-r-lg' : 'rounded-l-lg'"
      color="white"
      large
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <v-icon size="25">
        {{ icons.mdiFilterMenu }}
      </v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="isCustomizerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="400"
      class="app-customizer-drawer"
    >
      <div class="app-customizer-header customizer-section py-3">
        <span :style="`color: ${$vuetify.theme.themes.light.primary};font-size: 18px`">
          <b>{{ $t('menu.cars') }} </b>
          <span
            class="ml-5"
            :style="`color: ${$vuetify.theme.themes.light.primary};text-decoration: underline;cursor: pointer;font-size: 14px;`"
            @click="clearFiltros"
          >{{ $t('lbl.clearFilters') }}</span>
        </span><br />
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isCustomizerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!--MARCA-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('menu.marca') }}</span>
          <v-autocomplete
            v-model="filtersCars.marca_id"
            :items="itemsMarcas"
            :search-input.sync="searchMarca"
            hide-details
            hide-selected
            :label="$t('menu.marca')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="
              changeMarca()
              refresh()
            "
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.marca') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-avatar v-if="item.icon !== null">
                <v-img
                  size="20"
                  :src="rutaPublic + item.icon"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-avatar v-else>
                <v-avatar
                  color="primary"
                  size="30"
                >
                  <span class="white--text text-h5">{{ item.name.substr(0, 1) }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <v-divider v-if="filtersCars.marca_id"></v-divider>

        <!--MODELO-->
        <div
          v-if="filtersCars.marca_id"
          class="customizer-section"
        >
          <span class="text--primary">{{ $t('menu.modelo') }}</span>
          <v-select
            v-model="filtersCars.modelo_id"
            :items="modelosDisp"
            item-text="name"
            item-value="id"
            :label="$t('menu.modelo')"
            outlined
            dense
            hide-details
            @change="refresh()"
          ></v-select>
        </div>

        <v-divider></v-divider>

        <!--TRASNMISION-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('menu.trasnmision') }}</span>
          <v-select
            v-model="filtersCars.transmision_id"
            :items="transmisiones"
            :label="$t('menu.trasnmision')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            @change="refresh()"
          ></v-select>
        </div>

        <v-divider></v-divider>

        <!--TIPO AUTO-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('menu.typeCar') }}</span>
          <v-autocomplete
            v-model="filtersCars.tipo_auto_id"
            :items="itemsType"
            :search-input.sync="searchType"
            hide-details
            hide-selected
            :label="$t('menu.typeCar')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="
              refresh()
            "
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.typeCar') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <v-divider></v-divider>

        <!--NOMBRE DEL AUTO-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.car') }}</span>
          <v-text-field
            v-model="filtersCars.name"
            :label="$t('lbl.car')"
            outlined
            dense
            single-line
            :append-icon="icons.mdiMagnify"
            @input="refresh()"
          >
          </v-text-field>
        </div>

        <v-divider></v-divider>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'

import {
  mdiFilterMenu, mdiClose, mdiStarOutline, mdiStar, mdiStarHalfFull, mdiMagnify,
} from '@mdi/js'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// 3rd Party

export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    marcas: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    modelos: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    typeAuto: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    combustibles: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    transmisiones: {
      type: Array,
    },
  },
  setup() {
    const isCustomizerOpen = ref(false)
    // eslint-disable-next-line object-curly-newline
    const {
      isDark,
      isRtl,
      themes,
    } = useAppConfig()

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      isCustomizerOpen,

      isDark,
      isRtl,
      themes,

      // Perfect scrollbar options
      perfectScrollbarOptions,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      menuFromReserve: false,
      menuToReserve: false,
      menuFromService: false,
      menuToService: false,
      afiliadosList: [],
      afiliadosSearch: null,
      sellerList: [],
      sellerSearch: null,
      codesReserva: [],
      searchCodeReserva: null,
      icons: {
        mdiFilterMenu,
        mdiClose,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
        mdiMagnify,
      },
      permisos: sessionStorage.getItem('permisos_auth'),
      itemsMarcas: [],
      searchMarca: null,
      modelosDisp: [],
      itemsModelos: [],
      searchModelo: null,
      itemsTypeAuto: [],
      searchTypeAuto: null,
      itemsType: [],
      searchType: null,
      filtersCars: {},
    }
  },
  computed: {
    ...mapState({
      // filtersCars: state => state.app.filtersCars,
    }),
  },
  watch: {
    searchMarca(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterMarca(val.toLowerCase())
      } else {
        this.itemsMarcas = []
      }
    },
    searchModelo(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterModelo(val.toLowerCase())
      } else {
        this.itemsModelos = []
      }
    },
    searchType(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterTypeAuto(val.toLowerCase())
      } else {
        this.itemsType = []
      }
    },
  },
  mounted() {
    this.modelosDisp = this.modelos
    this.itemsModelos = this.modelosDisp
  },
  methods: {
    ...mapMutations([
      'filtrarCars',
      'filtersCotizadorHotelsResult',
      'clearCotizadorHotelsResultFilters',
      'clearFiltrosCars',
    ]),
    filterMarca(v) {
      this.itemsMarcas = []
      if (v === '') {
        this.itemsMarcas = []
      } else {
        this.itemsMarcas = this.marcas.filter(e => e.name.toLowerCase())
      }
    },
    filterModelo(v) {
      this.itemsModelos = []
      if (v === '') {
        this.itemsModelos = []
      } else {
        this.itemsModelos = this.modelosDisp.filter(e => e.name.toLowerCase())
      }
    },
    filterTypeAuto(v) {
      this.itemsTypeAuto = []
      if (v === '') {
        this.itemsType = []
      } else {
        this.itemsType = this.typeAuto.filter(e => e.name.toLowerCase())
      }
    },
    changeMarca() {
      if (this.filtersCars.marca_id) {
        const marc = this.marcas.filter(a => a.id === this.filtersCars.marca_id)[0]
        this.modelosDisp = marc.modelos
      } else {
        this.modelosDisp = this.modelos
      }
    },
    clearFiltros() {
      this.filtersCars = {}
      this.$emit('refresh', { })
      this.isCustomizerOpen = false
    },
    refresh() {
      this.$emit('refresh', this.filtersCars)
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  position: fixed;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 7;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
